import { ConfigGroup, Permissions } from '../services';
import { interpolateUrl } from '../utils';

export enum Routes {
   ROOT = '/',
   ACCESS_DENIED = '/access_denied',
   NOT_FOUND = '/not_found',
   MOBILE_DETECTED = '/mobile_detected',
   WELCOME = '/welcome',
   LOGIN = '/login',
   PASSWORD_RESET = '/agents/:id/reset_password',
   AUTHORIZED_PASSWORD_RESET = '/reset_password',

   ADMIN_AGENTS = '/admin/agents',
   ADMIN_AGENTS_ACTIVITY = '/admin/agents/activity',
   ADMIN_AGENTS_AUDIT = '/admin/agents/audit',
   ADMIN_AGENT = '/admin/agents/:id',
   ADMIN_AGENT_ACTIVITY = '/admin/agents/:id/activity',
   ADMIN_AGENT_SETTINGS = '/admin/agents/:id/settings',
   ADMIN_ORG_ACCOUNTS = '/admin/org_accounts',

   CAFE_ARRAY = '/cafe/array',
   CAFE_ARRAY_WORKFLOW = '/cafe/array/batch_workflow/:id',
   CAFE_MEMBERS = '/cafe/members',
   CAFE_MEMBER = '/cafe/members/:id',
   CAFE_MEMBER_GENERAL = '/cafe/members/:id/general',
   CAFE_MEMBER_EARN = '/cafe/members/:id/earn',
   CAFE_MEMBER_EARN_PLUS = '/cafe/members/:id/earn_plus',
   CAFE_MEMBER_RISK = '/cafe/members/:id/risk',
   CAFE_MEMBER_SURVEYS = '/cafe/members/:id/surveys',
   CAFE_MEMBER_ALL = '/cafe/members/:id/all',
   CAFE_STREAKS = '/cafe/streaks',
   CAFE_STREAK = '/cafe/streaks/:id',

   MARKETING_EARN = '/marketing/earn',
   MARKETING_PROMOTIONS = '/marketing/promotions',
   MARKETING_PROMOTIONS_ARCHIVED = '/marketing/promotions/archived',
   MARKETING_PROMOTION = '/marketing/promotions/:id',
   MARKETING_BOOSTERS = '/marketing/boosters',
   MARKETING_BOOSTER = '/marketing/boosters/:id',

   LATTICE_REPORTS = '/lattice',
   LATTICE_DAILY_EXECUTIVE_REPORT = '/lattice/daily_executive/:id',

   DATA_DEPOT_REFERRALS = '/referrals',
   DATA_DEPOT_REFERRALS_LIST = '/referrals/list',
   DATA_DEPOT_REFERRALS_EXPORTS = '/referrals/exports',
   DATA_DEPOT_REFERRALS_AGGREGATES = '/referrals/aggregates',
   DATA_DEPOT_BRIDGE_BUCKS = '/data_depot/bridge_bucks',
   DATA_DEPOT_DIAMOND_DROPS = '/data_depot/diamond_drops',
   DATA_DEPOT_FRAUD_EVENTS = '/data_depot/fraud_events',
   DATA_DEPOT_EXECUTIVE_RECENT = '/data_depot/daily_executive',
   DATA_DEPOT_ACTION_EVENTS = '/data_depot/action_events',
   DATA_DEPOT_GAME_EVENTS = '/data_depot/game_events',
   DATA_DEPOT_SURVEY_EVENTS = '/data_depot/survey_events',
   DATA_DEPOT_AD_EVENTS = '/data_depot/ad_events',
   DATA_DEPOT_AFFILIATE_EVENTS = '/data_depot/affiliate_events',

   RISKOPS_ACH_RETURNS_RECENT = '/riskops/ach_returns',
   RISKOPS_DISPUTES = '/riskops/disputes',

   RISKOPS_NEGATIVE_BALANCES_RECENT = '/riskops/negative_balances',
   RISKOPS_NEGATIVE_BALANCES_REPORT = '/riskops/negative_balances/:id',

   SHIPYARD_SYS_CON = '/shipyard/sys_con/:group',

   SHIPYARD_BRIDGE_TILLO_MERCHANTS = '/shipyard/tillo_merchants',
   SHIPYARD_BRIDGE_TILLO_MERCHANT = '/shipyard/tillo_merchants/:id',

   SHIPYARD_PARTNERSHIPS = '/shipyard/affiliates',
   SHIPYARD_PARTNERSHIPS_WATERFALL = '/shipyard/affiliates/waterfall',
   SHIPYARD_PARTNERSHIPS_GROUPS = '/shipyard/affiliates/groups',
   SHIPYARD_PARTNERSHIPS_AFFILIATE = '/shipyard/affiliates/:id',
   SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD = '/shipyard/affiliates/wizard',
   SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD_GENERAL = '/shipyard/affiliates/wizard/general',
   SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD_TIERS = '/shipyard/affiliates/wizard/tiers',
   SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD_POSTBACK_URL = '/shipyard/affiliates/wizard/postback_url',
   SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD_ASSETS = '/shipyard/affiliates/wizard/assets',
   SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD_CHECKOUT_SCREEN = '/shipyard/affiliates/wizard/checkout_screen',
   SHIPYARD_PARTNERSHIPS_AFFILIATE_WIZARD_CHECKOUT_SCREEN_FEATURES = '/shipyard/affiliates/wizard/checkout_screen_features',
   SHIPYARD_ARCADE = '/shipyard/arcade',
   SHIPYARD_ARCADE_USER_GAMES = '/shipyard/arcade/user_games',
   SHIPYARD_ARCADE_GAMES = '/shipyard/arcade/games',
   SHIPYARD_ARCADE_BESITOS = '/shipyard/arcade/besitos',
   SHIPYARD_ARCADE_BESITOS_GAME = '/shipyard/arcade/besitos/:id',
   SHIPYARD_ARCADE_GAMES_WATERFALL = '/shipyard/arcade/games_waterfall',
   SHIPYARD_ARCADE_FEATURED_WATERFALL = '/shipyard/arcade/featured_waterfall',
   SHIPYARD_ARCADE_GAME = '/shipyard/arcade/games/:id',
   SHIPYARD_ARCADE_EVENTS = '/shipyard/arcade/events',
   SHIPYARD_ARCADE_GAME_WIZARD = '/shipyard/arcade/games/wizard',
   SHIPYARD_ARCADE_GAME_WIZARD_GENERAL = '/shipyard/arcade/games/wizard/general',
   SHIPYARD_ARCADE_GAME_WIZARD_LEVELS = '/shipyard/arcade/games/wizard/levels',
   SHIPYARD_ARCADE_GAME_WIZARD_ASSETS = '/shipyard/arcade/games/wizard/assets',
   SHIPYARD_ARCADE_GAME_WIZARD_TAGS = '/shipyard/arcade/games/wizard/tags',
   SHIPYARD_ARCADE_GAME_WIZARD_FEATURING = '/shipyard/arcade/games/wizard/featuring',

   LABS_EXPERIMENTS = '/labs/experiments',
   LABS_EXPERIMENTS_ONGOING = '/labs/experiments/ongoing',
   LABS_EXPERIMENTS_ARCHIVED = '/labs/experiments/archived',
   LABS_EXPERIMENTS_SNAPSHOTS = '/labs/experiments/snapshots',

   SAILFISH_QUALIFICATIONS = '/sailfish/qualifications',
   SAILFISH_QUALIFICATIONS_QUESTION = '/sailfish/qualifications/:id',
   SAILFISH_QUALIFICATIONS_ANSWERS = '/sailfish/qualifications/answers',
   SAILFISH_SURVEYS = '/sailfish/surveys',
   SAILFISH_REWARDS = '/sailfish/rewards',
   SAILFISH_REWARDS_REDIRECTS = '/sailfish/rewards/redirects',
   SAILFISH_REWARDS_COMPLETES = '/sailfish/rewards/completes',
   SAILFISH_SURVEY = '/sailfish/surveys/:id',
   SAILFISH_SURVEYS_YIELD_MANAGEMENT = '/sailfish/surveys/yield_management',
}

export interface Route {
   title: string;
   route: Routes | string;
   icon: string;
   featureFlag?: string;
   forRoles?: string[];
   permission: Permissions;
}

export const DashboardRoute: Route = {
   title: 'Dashboard',
   route: Routes.ROOT,
   icon: '',
   permission: Permissions.VIEW_MEMBERS,
};

export const UsersRoute: Route = {
   title: 'Members',
   route: Routes.CAFE_MEMBERS,
   icon: '/icons/users.png',
   permission: Permissions.VIEW_MEMBERS,
};

export const ArrayRoute: Route = {
   title: 'Array',
   route: Routes.CAFE_ARRAY,
   icon: '/icons/array.png',
   permission: Permissions.VIEW_MEMBERS,
};

export const AdminOrgAccountsRoute: Route = {
   title: 'Org Accounts',
   route: Routes.ADMIN_ORG_ACCOUNTS,
   icon: '/icons/accounts.png',
   permission: Permissions.MANAGE_PRODUCT,
   forRoles: ['admin'],
};

export const EarnRoute: Route = {
   title: 'Earn',
   route: Routes.MARKETING_EARN,
   icon: '/icons/earn.png',
   permission: Permissions.MANAGE_MARKETING,
};

export const PromotionsRoute: Route = {
   title: 'Promotions',
   route: Routes.MARKETING_PROMOTIONS,
   icon: '/icons/promotions.png',
   permission: Permissions.MANAGE_MARKETING,
};

export const BoostersRoute: Route = {
   title: 'Bridge Boosters',
   route: Routes.MARKETING_BOOSTERS,
   icon: '/icons/shout.png',
   permission: Permissions.MANAGE_MARKETING,
   forRoles: ['admin'],
};

export const DataDepotDailyExecutiveRoute: Route = {
   title: 'Daily Executive',
   route: Routes.DATA_DEPOT_EXECUTIVE_RECENT,
   icon: '/icons/daily_executive_report.png',
   permission: Permissions.VIEW_REPORTS,
};

export const LatticeReportsRoute: Route = {
   title: 'Reports',
   route: Routes.LATTICE_REPORTS,
   icon: '/icons/reports.png',
   permission: Permissions.VIEW_REPORTS,
};

export const DataDepotReferralsRoute: Route = {
   title: 'Referrals',
   route: Routes.DATA_DEPOT_REFERRALS,
   icon: '/icons/users.png',
   permission: Permissions.VIEW_REPORTS,
};

export const RiskOpsAchReturnsRoute: Route = {
   title: 'ACH Returns',
   route: Routes.RISKOPS_ACH_RETURNS_RECENT,
   icon: '/icons/return.png',
   permission: Permissions.VIEW_REPORTS,
};

export const RiskOpsDisputesRoute: Route = {
   title: 'Disputes',
   route: Routes.RISKOPS_DISPUTES,
   icon: '/icons/disputes.png',
   permission: Permissions.MANAGE_RISK_OPS,
};

export const DataDepotFraudEventsRoute: Route = {
   title: 'Fraud Events',
   route: Routes.DATA_DEPOT_FRAUD_EVENTS,
   icon: '/icons/fraud.png',
   permission: Permissions.MANAGE_RISK_OPS,
};

export const RiskOpsNegBalanceRoute: Route = {
   title: 'Negative Balances',
   route: Routes.RISKOPS_NEGATIVE_BALANCES_RECENT,
   icon: '/icons/decline.png',
   permission: Permissions.VIEW_REPORTS,
};

export const AdminAgentsRoute: Route = {
   title: 'Agent Management',
   route: Routes.ADMIN_AGENTS,
   icon: '/icons/agent.png',
   permission: Permissions.MANAGE_AGENTS,
};

export const ShipyardSysConRoute: Route = {
   title: 'Sys Con',
   route: interpolateUrl(Routes.SHIPYARD_SYS_CON, { group: ConfigGroup.DEFAULT }),
   icon: '/icons/settings.png',
   permission: Permissions.VIEW_MEMBERS,
};

export const DataDepotDiamondDropsRoute: Route = {
   title: 'Diamond Drops',
   route: Routes.DATA_DEPOT_DIAMOND_DROPS,
   icon: '/icons/gem.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const ShipyardTilloMerchantsRoute: Route = {
   title: 'Tillo Merchant Manager',
   route: Routes.SHIPYARD_BRIDGE_TILLO_MERCHANTS,
   icon: '/icons/bridge_bucks.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const DataDepotBridgeBucksRoute: Route = {
   title: 'Bridge Bucks',
   route: Routes.DATA_DEPOT_BRIDGE_BUCKS,
   icon: '/icons/flying_money.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const DataDepotActionEventsRoute: Route = {
   title: 'Action Events',
   route: Routes.DATA_DEPOT_ACTION_EVENTS,
   icon: '/icons/actions.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const DataDepotGameEventsRoute: Route = {
   title: 'Game Events',
   route: Routes.DATA_DEPOT_GAME_EVENTS,
   icon: '/icons/games.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const DataDepotSurveyEventsRoute: Route = {
   title: 'Survey Events',
   route: Routes.DATA_DEPOT_SURVEY_EVENTS,
   icon: '/icons/surveys.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const DataDepotAdEventsRoute: Route = {
   title: 'Ad Events',
   route: Routes.DATA_DEPOT_AD_EVENTS,
   icon: '/icons/ads.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const DataDepotAffiliateEventsRoute: Route = {
   title: 'Affiliate Events',
   route: Routes.DATA_DEPOT_AFFILIATE_EVENTS,
   icon: '/icons/affiliates.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const ShipyardPartnershipsRoute: Route = {
   title: 'Partnerships',
   route: Routes.SHIPYARD_PARTNERSHIPS,
   icon: '/icons/partnerships.png',
   permission: Permissions.VIEW_MEMBERS,
};
export const ShipyardArcadeGamesRoute: Route = {
   title: 'Arcade Portal',
   route: Routes.SHIPYARD_ARCADE,
   icon: '/icons/games.png',
   permission: Permissions.VIEW_MEMBERS,
};

export const LabsExperimentsRoute: Route = {
   title: 'Experiments',
   route: Routes.LABS_EXPERIMENTS,
   icon: '/icons/experiments.png',
   permission: Permissions.VIEW_LABS,
};

export const StreaksRoute: Route = {
   title: 'Streaks',
   route: Routes.CAFE_STREAKS,
   icon: '',
   permission: Permissions.VIEW_MEMBERS,
};

export const SailfishQualificationsRoute: Route = {
   title: 'Qualifications',
   route: Routes.SAILFISH_QUALIFICATIONS,
   icon: '/icons/seaport.png',
   permission: Permissions.VIEW_MEMBERS,
};

export const SailfishSurveysRoute: Route = {
   title: 'Surveys',
   route: Routes.SAILFISH_SURVEYS,
   icon: '/icons/offers.png',
   permission: Permissions.VIEW_MEMBERS,
};

export const SailfishRewardsRoute: Route = {
   title: 'Rewards',
   route: Routes.SAILFISH_REWARDS,
   icon: '/icons/settings.png',
   permission: Permissions.VIEW_MEMBERS,
};

export const sideMenuRoutes: Route[] = [
   BoostersRoute,
   DashboardRoute,
   EarnRoute,
   LabsExperimentsRoute,
   DataDepotDailyExecutiveRoute,
   DataDepotActionEventsRoute,
   DataDepotSurveyEventsRoute,
   DataDepotAdEventsRoute,
   DataDepotAffiliateEventsRoute,
   DataDepotGameEventsRoute,
   LatticeReportsRoute,
   DataDepotReferralsRoute,
   AdminOrgAccountsRoute,
   PromotionsRoute,
   RiskOpsAchReturnsRoute,
   RiskOpsDisputesRoute,
   DataDepotFraudEventsRoute,
   RiskOpsNegBalanceRoute,
   AdminAgentsRoute,
   DataDepotDiamondDropsRoute,
   ShipyardSysConRoute,
   ShipyardTilloMerchantsRoute,
   DataDepotBridgeBucksRoute,
   ShipyardPartnershipsRoute,
   UsersRoute,
   ArrayRoute,
   StreaksRoute,
   SailfishQualificationsRoute,
   SailfishSurveysRoute,
   SailfishRewardsRoute,
   ShipyardArcadeGamesRoute,
];
