import React from 'react';
import { useLocation } from 'react-router-dom';
import * as H from 'history';
import { NavigationStore, useNavigationStore, UserStore, useUserStore } from '../../../store';
import {
   BoostersRoute,
   AdminAgentsRoute,
   PromotionsRoute,
   DataDepotDailyExecutiveRoute,
   AdminOrgAccountsRoute,
   Routes,
   ShipyardSysConRoute,
   sideMenuRoutes,
   UsersRoute,
   LabsExperimentsRoute,
   DataDepotDiamondDropsRoute,
   ArrayRoute,
   DataDepotReferralsRoute,
   ShipyardTilloMerchantsRoute,
   DataDepotBridgeBucksRoute,
   ShipyardPartnershipsRoute,
   ShipyardArcadeGamesRoute,
   DataDepotFraudEventsRoute,
   DataDepotActionEventsRoute,
   DataDepotGameEventsRoute,
   DataDepotSurveyEventsRoute,
   DataDepotAdEventsRoute,
   DataDepotAffiliateEventsRoute,
   SailfishQualificationsRoute,
   SailfishSurveysRoute,
   SailfishRewardsRoute,
} from '../../../routes/Routes';
import { List } from '@mui/material';
import { NavigationNamespace } from './NavigationNamespace';

export interface NavigationProps {
   collapsed?: boolean;
}

const Navigation = React.memo(({ collapsed = false }: NavigationProps): JSX.Element => {
   const active = useNavigationStore((state: NavigationStore) => state.activeRoute);
   const setActiveRoute = useNavigationStore((state: NavigationStore) => state.setActiveRoute);
   const agent = useUserStore((state: UserStore) => state.agent);

   const location: H.Location = useLocation();

   React.useEffect(() => {
      let route = null;

      route = sideMenuRoutes.find(
         (r) => r.route !== Routes.ROOT && r.route !== Routes.WELCOME && location.pathname.indexOf(r.route) !== -1,
      );

      if (route) {
         setActiveRoute(route);
      }
   }, [location]);

   return (
      <List disablePadding>
         <NavigationNamespace
            collapsed={collapsed}
            agent={agent}
            title="Bridge Café"
            routes={[UsersRoute, ArrayRoute]}
            activeRoute={active}
            icon="/icons/bridge_cafe.png"
         />

         <NavigationNamespace
            collapsed={collapsed}
            agent={agent}
            title="Shipyard"
            routes={[
               ShipyardSysConRoute,
               ShipyardTilloMerchantsRoute,
               ShipyardPartnershipsRoute,
               ShipyardArcadeGamesRoute,
            ]}
            activeRoute={active}
            icon="/icons/shipyard.png"
         />

         <NavigationNamespace
            collapsed={collapsed}
            agent={agent}
            title="Data Depot"
            routes={[
               DataDepotDailyExecutiveRoute,
               DataDepotReferralsRoute,
               DataDepotBridgeBucksRoute,
               DataDepotGameEventsRoute,
               DataDepotActionEventsRoute,
               DataDepotSurveyEventsRoute,
               DataDepotAdEventsRoute,
               DataDepotAffiliateEventsRoute,
               DataDepotDiamondDropsRoute,
               DataDepotFraudEventsRoute,
            ]}
            activeRoute={active}
            icon="/icons/lattice.png"
         />

         <NavigationNamespace
            collapsed={collapsed}
            agent={agent}
            title="Marketing"
            routes={[BoostersRoute, PromotionsRoute]}
            activeRoute={active}
            icon="/icons/marketing.png"
         />

         <NavigationNamespace
            collapsed={collapsed}
            agent={agent}
            title="Gold Surveys"
            routes={[SailfishQualificationsRoute, SailfishSurveysRoute, SailfishRewardsRoute]}
            activeRoute={active}
            icon="/icons/shipyard.png"
         />

         <NavigationNamespace
            collapsed={collapsed}
            agent={agent}
            title="Labs"
            routes={[LabsExperimentsRoute]}
            activeRoute={active}
            icon="/icons/labs.png"
         />

         <NavigationNamespace
            collapsed={collapsed}
            agent={agent}
            title="Admin"
            routes={[AdminAgentsRoute, AdminOrgAccountsRoute]}
            activeRoute={active}
            icon="/icons/lattice.png"
         />
      </List>
   );
});

export default Navigation;
export { Navigation };
