import React from 'react';
import { RowBox, Time } from '../../common';

import styles from './ToolbarRight.module.scss';
import { QuickSearchBar } from '../quickSearch';

export interface ToolbarRightProps {
   onSignOut?: () => void;
   isCafePage?: boolean;
}

const ToolbarRight = React.memo(({ isCafePage }: ToolbarRightProps): JSX.Element => {
   return (
      <RowBox style={{ justifyContent: 'flex-end' }}>
         <QuickSearchBar />
         <Time className={styles.time} />
      </RowBox>
   );
});

export default ToolbarRight;
export { ToolbarRight };
