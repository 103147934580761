import React from 'react';
import { create } from 'zustand';

export enum ConfirmationSeverity {
   WARNING = 'warning',
   ERROR = 'error',
   SUCCESS = 'success',
   INFO = 'info',
}

interface ConfirmationStoreData {
   title: string | null;
   message: string | null;
   severity: ConfirmationSeverity | null;
   onAction: ((result: boolean) => void) | undefined;
   opened: boolean;
   children?: React.ReactNode | (() => React.ReactNode);
}

interface ConfirmationStoreActions {
   clear: () => void;
   setTitle: (title: string | null) => void;
   setMessage: (message: string | null) => void;
   setSeverity: (severity: ConfirmationSeverity | null) => void;
   setOnAction: (onAction: ((result: boolean) => void) | undefined) => void;
   toggle: (opened: boolean) => void;
   setChildren: (children?: React.ReactNode | (() => React.ReactNode) | undefined) => void;
}

export type ConfirmationStore = ConfirmationStoreData & ConfirmationStoreActions;

const initialState: ConfirmationStoreData = {
   title: null,
   message: null,
   severity: null,
   onAction: () => {},
   opened: false,
   children: undefined,
};

export const useConfirmationStore = create<ConfirmationStore>()(
   (set, get): ConfirmationStore => ({
      ...initialState,

      clear: () => set({ ...initialState }),

      setTitle: (title: string | null) => set({ title }),
      setMessage: (message: string | null) => set({ message }),
      setSeverity: (severity: ConfirmationSeverity | null = ConfirmationSeverity.SUCCESS) => set({ severity }),
      setOnAction: (onAction: ((result: boolean) => void) | undefined) => set({ onAction }),
      toggle: (opened: boolean) => set({ opened }),
      setChildren: (children?: React.ReactNode | (() => React.ReactNode) | undefined) => set({ children }),
   }),
);
