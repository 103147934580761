import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';

import './index.css';
import { getEnv, getSentryDsn } from './utils/env';

const env = getEnv();

Sentry.init({
   dsn: getSentryDsn(),
   integrations: [Sentry.browserTracingIntegration()],
   environment: env,
   enabled: false,
   // Tracing
   tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

ReactDOM.createRoot(document.getElementById('root')!).render(
   <React.StrictMode>
      <App />
   </React.StrictMode>,
);
